.products {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    justify-content: center;
}

@media screen and (max-width: 1140px) {
    .products {
        grid-template-columns: 20% 20%;
    }

}

@media screen and (max-width: 840px) {
    .products {
        grid-template-columns: 40% 40%;
    }

    .titlePromotions {
        text-align: left;
    }

}

@media screen and (max-width: 510px) {
    .products {
        grid-template-columns: 60% 60%;
    }

    .titlePromotions {
        text-align: left;
    }


}

.product {
    background-color: white;
    width: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2.8px 2.2px rgba(220, 20, 60, 0.034), 0 6.7px 5.3px rgba(220, 20, 60, 0.048), 0 12.5px 10px rgba(220, 20, 60, 0.06), 0 22.3px 17.9px rgba(220, 20, 60, 0.072), 0 41.8px 33.4px rgba(220, 20, 60, 0.086), 0 100px 80px rgba(220, 20, 60, 0.12), 0 100px 80px rgba(255, 255, 255, 0.12);
    margin-bottom: 10px;

}

.product .headProduct {
    padding: 20px;
}

.btnProduct {
    background-color: #C61C1C;
    color: white;
    font-size: 32px;
    width: 100%;
    transition: all 0.3s;
}

.btnProduct:hover {
    background-color: white;
    color: black;

}

.titlePromotions {
    text-align: center;
}